import { type FC } from 'react'

import { useGetUserDetailsQuery } from '@store/services/user'
import { useGetProductsQuery, useUpdateLineItemInCartMutation } from '@store/services/ecommerce'

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent
} from '@mui/material'
import { type ILineItem } from 'src/types/cart.types'

type QuantitySelectProps = {
  lineItem: ILineItem
  cartId: string
}

const QuantitySelect: FC<QuantitySelectProps> = ({ lineItem, cartId }) => {
  const { data: userDetailsData } = useGetUserDetailsQuery()
  const { data: productsData } = useGetProductsQuery({ userId: Number(userDetailsData?.id), partner: userDetailsData?.partners[0] })
  const [updateLineItem] = useUpdateLineItemInCartMutation()

  const variantId = productsData
    ?.find((product) => product.productType === lineItem.productType)
    ?.variants.find(
      (variant) => variant.purchaseType === lineItem.purchaseType
    )?.variantId ?? ''

  const handleChange = async (event: SelectChangeEvent<number>) => {
    await updateLineItem({
      userId: Number(userDetailsData?.id),
      cartId,
      lineItemId: lineItem.lineId,
      variantId,
      quantity: Number(event.target.value)
    })
  }

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Quantity</InputLabel>
      <Select
        onChange={handleChange}
        value={lineItem.quantity}
        label="Quantity"
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        {lineItem.quantity > 5 && (
          <MenuItem value={lineItem.quantity}>{lineItem.quantity}</MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default QuantitySelect
