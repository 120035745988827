import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'
import { ecommerceUrl } from '@constants'
import { ICart, IOrder, IProduct } from '@types'
import { IPartner } from '@testavivadk/common-tools/types'

export const ECOMMERCE_API_REDUCER_KEY = 'ecommerceApi'
export const ecommerceApi = createApi({
  reducerPath: ECOMMERCE_API_REDUCER_KEY,
  tagTypes: ['EcommerceProducts', 'EcommerceCart', 'EcommerceOrders'],
  baseQuery: baseQueryWithAuth({ baseUrl: `${ecommerceUrl}/api/v1` }),
  endpoints: (builder) => ({
    getOrders: builder.query<IOrder[], { userId: number }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/orders`,
        method: 'GET'
      }),
      providesTags: ['EcommerceOrders']
    }),
    getProducts: builder.query<IProduct[], { partner?: IPartner, userId: number }>({
      query: ({ partner, userId }) => ({
        url: `/products?userId=${userId}${
          partner != null ? `&partner=${partner}` : ''
        }`,
        method: 'GET'
      }),
      providesTags: ['EcommerceProducts']
    }),
    getCart: builder.query<ICart, { userId: number }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/cart`,
        method: 'GET'
      }),
      providesTags: ['EcommerceCart']
    }),
    getItemsInCart: builder.query<{ items: number }, { userId: number }>({
      query: ({ userId }) => ({
        url: `/users/${userId}/cart/items`,
        method: 'GET'
      }),
      providesTags: ['EcommerceCart']
    }),
    updateLineItemInCart: builder.mutation<unknown, { userId: number, cartId: string, lineItemId: string, variantId: string, quantity: number }>({
      query: ({
        userId, cartId, lineItemId, variantId, quantity
      }) => ({
        url: `/users/${userId}/cart`,
        method: 'PATCH',
        body: {
          cartId,
          lines: [
            {
              quantity,
              merchandiseId: variantId,
              id: lineItemId
            }
          ]
        },
        headers: { 'Content-Type': 'application/json' }
      }),
      async onQueryStarted({ userId, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          ecommerceApi.util.updateQueryData('getCart', { userId }, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: ['EcommerceCart']
    }),
    removeLineItemFromCart: builder.mutation<unknown, { userId: number, cartId: string, lineItemId: string }>({
      query: ({ userId, cartId, lineItemId }) => ({
        url: `/users/${userId}/cart`,
        method: 'DELETE',
        body: {
          cartId,
          lineIds: [lineItemId]
        },
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: ['EcommerceCart']
    }),
    createCart: builder.mutation<unknown, { userId: number, variantId: string }>({
      query: ({ userId, variantId }) => ({
        url: `/users/${userId}/cart`,
        method: 'POST',
        body: {
          lines: [
            {
              quantity: 1,
              merchandiseId: variantId
            }
          ]
        },
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: ['EcommerceCart']
    }),
    addLineItemToCart: builder.mutation<unknown, { userId: number, cartId: string, variantId: string }>({
      query: ({ userId, cartId, variantId }) => ({
        url: `/users/${userId}/cart`,
        method: 'PUT',
        body: {
          cartId,
          lines: [
            {
              quantity: 1,
              merchandiseId: variantId
            }
          ]
        },
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: ['EcommerceCart']
    })
  })
})

export const {
  useGetOrdersQuery,
  useGetProductsQuery,
  useGetCartQuery,
  useCreateCartMutation,
  useAddLineItemToCartMutation,
  useUpdateLineItemInCartMutation,
  useRemoveLineItemFromCartMutation
} = ecommerceApi
